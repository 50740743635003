@import '../../../../scss/theme-bootstrap';

.waitlist-form {
  max-width: 425px;
  height: 300px;
  text-align: center;
  margin-#{$rdirection}: 25px;
  @include breakpoint($medium-up) {
    height: 250px;
  }
  &__header {
    @include font--subheading;
    text-transform: uppercase;
    font-size: 55px;
    margin-bottom: 14px;
    line-height: normal;
  }
  &__message {
    line-height: 1.5em;
    font-size: 16px;
  }
  &__email {
    width: 265px;
  }
}
